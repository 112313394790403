import { useEffect } from 'react';

const InfoCuentas = ({ onDataUpdate }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/infocuentas');
        const jsonData = await response.json();

        // Llama a la función onDataUpdate para proporcionar los datos al componente padre.
        if (onDataUpdate) {
          onDataUpdate(jsonData);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, [onDataUpdate]);

  // Puedes devolver algo en el render, aunque no es necesario si solo estás utilizando el componente para obtener datos.
  return null;
};

export default InfoCuentas;
