import React from 'react';
import './PublicaConNosotros.css';

const Catalogo = ({
  productosDestacados,
  onProductoClick,
  productoSeleccionado,
  setProductoSeleccionado,
  setCantidad,
  cantidad,
  manejarClickPublicar,
  mostrarCatalogo
}) => {
  return (
    <div>
      {mostrarCatalogo && (
        <div id="catalogo">
          <h2>Selecciona el tipo de aviso</h2>
          <div className="catalogobox">
            {productosDestacados.map((producto) => (
              <div
                key={producto.Idproducto}
                className="recuadro"
                onClick={() => onProductoClick(producto)}
              >
                <div className="prodcatalogo">
                  <div>
                    <p>{producto.Nombre}</p>
                  </div>
                  <div>
                    <p>{producto.Descripcion}</p>
                  </div>
                  <div>
                    <p style={{ whiteSpace: 'pre-line' }}>{producto.properties}</p>
                  </div>
                </div>
                <button type="button">Ver más</button>
              </div>
            ))}
          </div>
        </div>
      )}

      {productoSeleccionado && mostrarCatalogo && (
        <div>
          <h3>{productoSeleccionado.Nombre}</h3>
          <p>{productoSeleccionado.LongDesc}</p>
          <div>
            {productoSeleccionado.Idproducto !== 3 ? (
              <>
                <button onClick={() => setCantidad(cantidad - 1)}>-</button>
                <span>{cantidad}</span>
                <button onClick={() => setCantidad(cantidad + 1)}>+</button>
                <button id="btn-comprar">
                  Comprar
                </button>
              </>
            ) : (
              <button id="btn-publicar" onClick={manejarClickPublicar}>
                Empezar a publicar ahora
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Catalogo;
