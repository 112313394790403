import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Productos from './components/Productos';
import Aviso from './Pages/Aviso/Aviso';
import Cuenta from './Pages/Cuenta/Cuenta';
import Inicio from './Pages/Inicio/Inicio';
import TiendasDestacadas from './components/tiendas-destacadas/TiendasDestacadas';
import Begin from './Pages/Begin/Begin'; // Página de Login
import Contacto from './Pages/Contacto/Contacto';
import FloatingCard from './components/Floatings/FloatingCard';
import Navbar from './components/Navbar/Navbar';
import MostrarCookieSession from './components/Prueba';
import Precompra from './Pages/Precompra/Precompra';
import Cart from './Pages/Cart/Cart';
import { InfoCuentasProvider } from './Hooks/InfoCuentasContext'; // Asegúrate de importar el Provider

const App = () => {
  const [showFloatingCard, setShowFloatingCard] = useState(false);

  const handleToggleClick = () => {
    setShowFloatingCard(!showFloatingCard);
  };

  return (
    <Router>
      {/* Navbar solo si no estás en la página de login */}
      {window.location.pathname !== '/login' && <Navbar onToggleClick={handleToggleClick} />}
      
      <Routes>
        {/* Rutas sin Provider (por ejemplo, login) */}
        <Route path="/login" element={<Begin />} />
        
        {/* Rutas envueltas en el Provider */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/productos" element={<Productos />} />
          <Route path="/aviso/:id" element={<Aviso />} />
          <Route path="/cuenta" element={<Cuenta />} />
          <Route path="/" element={<Inicio />} />
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/tiendas" element={<TiendasDestacadas />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/previewbuy" element={<Precompra />} />
          <Route path="/tucarrito" element={<Cart />} />
          <Route path="/prueba" element={<MostrarCookieSession />} />
        </Route>
        
        {/* Ruta para redireccionar cualquier ruta no definida */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <FloatingCard
        className={showFloatingCard ? 'floating-card active' : 'floating-card'}
        onToggleClick={handleToggleClick} // Pass the function to FloatingCard
      />
    </Router>
  );
};

// Componente para envolver rutas que requieren el Provider
const ProtectedRoutes = () => {
  return (
    <InfoCuentasProvider>
      <Routes>
        {/* Aquí incluyes las rutas que requieren el Provider */}
        <Route path="/productos" element={<Productos />} />
        <Route path="/aviso/:id" element={<Aviso />} />
        <Route path="/cuenta" element={<Cuenta />} />
        <Route path="/" element={<Inicio />} />
        <Route path="/tiendas" element={<TiendasDestacadas />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/previewbuy" element={<Precompra />} />
        <Route path="/tucarrito" element={<Cart />} />
        <Route path="/prueba" element={<MostrarCookieSession />} />
      </Routes>
    </InfoCuentasProvider>
  );
};

export default App;
