import React from 'react';
import './FloatingCard.css';
import Loader from '../Loader/Loader';



const FloatingCard = ({ className, onToggleClick }) => {
  // Your existing FloatingCard component code

  const handleButtonClick = () => {
    // Perform any additional actions here
    onToggleClick(); // Call the function passed from App component
  };

  return (
    <div className={className} onClick={handleButtonClick}>
      <Loader />
    </div>
  );
};

export default FloatingCard;
