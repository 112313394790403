import React from 'react';
import { useParams } from 'react-router-dom';
import Postnav from '../../components/Postnav/Postnav';
import AvisoContent from '../../components/AvisoContent/AvisoContent'; 
import Footer from '../../components/Footer/Footer'

function Aviso() {
  const { id } = useParams();

  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <AvisoContent id={id} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Aviso;
