import React from 'react';
import PersonalInfo from '../../components/PersonalInfo/PersonalInfo';


function MisDatos() {

  return (
    <div>
      <div className="outheader">
        <div className="background">
            <div className='indexcss-margin1'>
              <div className="center-content-backgroundcolor">
                
                  <PersonalInfo />                    
                
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}

export default MisDatos;
