  import React, { useState } from 'react';
  // import Cookies from 'js-cookie';
  // import CryptoJS from 'crypto-js';
  import LogoSimpleBlack from './mobilelogoblack.png';
  import './Login.css';
  import LoginLoader from '../Loader/LoginLoader';

  function Login({ onToggle }) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const enviarDatos = async (event) => {
      console.log('Enviando datos...');

      event.preventDefault();

      const correo = document.getElementById('correo').value;
      const contraseña = document.getElementById('contraseña').value;

      const datos = {
        correo: correo,
        contraseña: contraseña
      };

      try {
        setLoading(true);

        const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(datos),
          credentials: 'include'
        });

        console.log('Response:', response);

        if (response.ok) {
          // const data = await response.json();
          // const sessionId = data.id;
          // const signature = CryptoJS.HmacSHA256(sessionId, 'tu_secreto').toString(CryptoJS.enc.Base64);
          // Cookies.set('sessionId', `${sessionId}.${signature}`);

          window.location.href = '/';
        } else if (response.status === 401) {
          setErrorMessage('Los datos ingresados son incorrectos');
        } else {
          console.error('Error en la respuesta del servidor:', response.status);
        }
      } catch (error) {
        console.error('Error en enviarDatos:', error);
      } finally {
        setLoading(false);
      }

      console.log('Datos enviados exitosamente.');
    };

    const redirigirASignin = () => {
      console.log('Redirigiendo a Signin...');
      onToggle();
      console.log('Redirección completada.');
    };

    return (
      <div className="centerform">
        <img className="logologin" src={LogoSimpleBlack} alt="Descripción de la imagen" />
        <div className="login-container">
          {!loading && <h2>Iniciar sesión</h2>}

          {loading ? (
            // Muestra LoginLoader cuando loading es true
            <LoginLoader />
          ) : (
            // Muestra el formulario solo cuando loading es false
            <form id="formulario" onSubmit={enviarDatos}>
              <div className="form-group">
                <label htmlFor="correo">Correo electrónico:</label>
                <input type="email" id="correo" name="correo" required />
              </div>
              <div className="form-group">
                <label htmlFor="contraseña">Contraseña:</label>
                <input type="password" id="contraseña" name="contraseña" required />
              </div>
              {errorMessage && <p style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</p>}
              <div className="form-group">
                <button type="submit">Iniciar sesión</button>
              </div>
            </form>
          )}

          {!loading && (
            <div className="form-group">
              <div className="outbutton">
                <p className="rounded-button" onClick={redirigirASignin}>
                  Creá tu cuenta
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  export default Login;
