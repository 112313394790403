import React, { useState, useEffect, useContext } from 'react';
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';
import Catalogo from './Catalogo';
import './PublicaConNosotros.css';

const PublicaConNosotros = () => {
  const {tiendas } = useContext(InfoCuentasContext);
  const [productosDestacados, setProductosDestacados] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [mostrarCatalogo, setMostrarCatalogo] = useState(true);
  const [mostrarPublicacion, setMostrarPublicacion] = useState(false);
  const [mostrarTienda, setMostrarTienda] = useState(false);

  useEffect(() => {
    const obtenerProductosDestacados = async () => {
      try {
        const url = `${window.location.origin}/api/catalogo`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al obtener productos');
        }

        const data = await response.json();
        const primerosProductos = data.slice(0, 4);
        const ordenProductos = [3, 1, 2, 4];
        primerosProductos.sort((a, b) => {
          return ordenProductos.indexOf(a.Idproducto) - ordenProductos.indexOf(b.Idproducto);
        });
        setProductosDestacados(primerosProductos);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerProductosDestacados();
  }, []);

  const mostrarDescripcion = (producto) => {
    setProductoSeleccionado(producto);
  };

  const agregarProducto = () => {
    setCantidad(cantidad + 1);
  };

  const restarProducto = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };

  const manejarClickPublicar = () => {
    if (tiendas === 'no') {
      setMostrarCatalogo(false);
      setMostrarPublicacion(false);
      setMostrarTienda(true);
      window.location.hash = 'crear-tienda';
    } else {
      setMostrarCatalogo(false);
      setMostrarTienda(false);
      setMostrarPublicacion(true);
      window.location.hash = 'crear-aviso';
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash === 'publicacion') {
        setMostrarCatalogo(false);
        setMostrarPublicacion(true);
        setMostrarTienda(false);
      } else if (hash === 'crear-tienda') {
        setMostrarCatalogo(false);
        setMostrarPublicacion(false);
        setMostrarTienda(true);
      } else {
        setMostrarCatalogo(true);
        setMostrarPublicacion(false);
        setMostrarTienda(false);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [tiendas]);

  return (
    <div>
      {mostrarCatalogo && !mostrarPublicacion && !mostrarTienda ? (
        <Catalogo
          productosDestacados={productosDestacados}
          onProductoClick={mostrarDescripcion}
          productoSeleccionado={productoSeleccionado}
          setProductoSeleccionado={setProductoSeleccionado}
          setCantidad={setCantidad}
          cantidad={cantidad}
          manejarClickPublicar={manejarClickPublicar}
          mostrarCatalogo={mostrarCatalogo}
        />
      ) : null}
    </div>
  );
};

export default PublicaConNosotros;
