import React from 'react';
import catImage from './load.gif';
import './Loader.css'

const LoginLoader = () => {
  const imageStyle = {
    maxWidth: '70%', // Ajusta el tamaño de la imagen según sea necesario
  };

  return (
    <div className='Loader'>
        <p>
        Gracias! 
        <p>
        Estamos confirmado que eres un usuario de Cannubis.
        </p>
        </p>
      <img src={catImage} alt="Cat" style={imageStyle} />
    </div>
  );
};

export default LoginLoader;