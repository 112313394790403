import React from 'react';
import catImage from './load.gif';
import './Loader.css'

const Loader = () => {
  const imageStyle = {
    maxWidth: '70%', // Ajusta el tamaño de la imagen según sea necesario
  };

  return (
    <div className='Loader'>
      <img src={catImage} alt="Cat" style={imageStyle} />
    </div>
  );
};

export default Loader;
