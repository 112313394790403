import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Asegúrate de tener el react-router-dom instalado

import Login3 from '../../components/LoginSignin/login3.png';
import Signin from '../../components/LoginSignin/Signin';
import Login from '../../components/LoginSignin/Login';
import '../../components/LoginSignin/Login.css';

function Begin() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tipo = queryParams.get('tipo');
  const [mostrarLogin, setMostrarLogin] = useState(true);
  

  useEffect(() => {
    if (tipo === 'signin') {
      setMostrarLogin(false);
    }
  }, [tipo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/infocuentas');
        if (response.status === 200) {
          // Redirigir a la ruta (/) si la respuesta es 200 (autorizada)
          window.location.href = '/';
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const toggleMostrarLogin = () => {
    setMostrarLogin(!mostrarLogin);
  };

  return (
    <div>
      <div className="section">
        <div className="left-section">
          {mostrarLogin ? <Login onToggle={toggleMostrarLogin} /> : <Signin />}
        </div>
        <div className="right-section">
          <img className="loginsection" src={Login3} alt="Descripción de la imagen" />
        </div>
      </div>
    </div>
  );
}

export default Begin;
