import React, { useState, useEffect, useContext } from 'react';
import Carrousel from '../Carrousels/Carrousel';
import Loader from '../Loader/Loader';
import './AvisoContent.css'; // Asegúrate de importar el archivo CSS
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';

function AvisoContent({ id }) {
  const [avisoInfo, setAvisoInfo] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [message, setMessage] = useState(''); // Agregamos estado para el mensaje
  const { isAuthenticated } = useContext(InfoCuentasContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${window.location.origin}/api/infoavisos/${id}`;
        const response = await fetch(url);
        const data = await response.json();
        if (response.ok) {
          setAvisoInfo(data);
        } else {
          throw new Error("La respuesta no es un JSON válido");
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, [id]);

  const restarUnidad = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  };

  const sumarUnidad = () => {
    setCantidad(cantidad + 1);
  };

  const agregarAlCarrito = async () => {
    setShowOverlay(true); // Mostrar el overlay inmediatamente

    if (!isAuthenticated) {
      setMessage('Debes iniciar sesión para agregar productos al carrito');
      return;
    }

    try {
      const data = {
        action: "paste",
        avisos: [{
          avisoid: id,
          cant: cantidad,
          precio: avisoInfo.Precio
        }]
      };

      const response = await fetch('/api/carrito', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setShowButtons(true); // Mostrar el cuadro con los botones
        setMessage('');
      } else {
        throw new Error('Error al agregar el producto al carrito');
      }
    } catch (error) {
      console.error("Error al agregar el producto al carrito:", error);
      setMessage(error.message);
    }
  };

  const handleOverlayClick = (action) => {
    setShowOverlay(false);
    if (action === 'ir') {
      window.location.href = '/tucarrito';
    } else if (action === 'seguir') {
      window.location.href = '/productos';
    }
  };

  return (
    <div className="maincenterbackground">
      {avisoInfo ? (
        <div>
          <h2>{avisoInfo.Titulo}</h2>
          <p>{avisoInfo.Descripcion}</p>
          <p>Precio: {avisoInfo.Precio}</p>
          <p>Stock: {avisoInfo.Stockproducto}</p>
          <Carrousel imagenes={Object.values(avisoInfo).filter(value => value && typeof value === 'string' && value.startsWith('https://'))} />

          <div>
            <button onClick={restarUnidad}>-</button>
            <span>{cantidad}</span>
            <button onClick={sumarUnidad}>+</button>
          </div>

          <button className="comprar-button" onClick={() => alert("Comprar")}>Comprar</button>
          <button onClick={agregarAlCarrito}>Agregar al carrito</button>

          {showOverlay && (
            <div className="overlay">
              <div className="overlay-content">
                {message ? (
                  <>
                    <p>{message}</p>
                    <button onClick={() => window.location.href = '/login'}>Iniciar sesión</button>
                  </>
                ) : (
                  <>
                    <p>Tu producto fue añadido al carrito!</p>
                    {showButtons && (
                      <div className="buttons">
                        <button onClick={() => handleOverlayClick('ir')}>Ir al carrito</button>
                        <button onClick={() => handleOverlayClick('seguir')}>Seguir comprando</button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Loader />
          <p>Cargando información del aviso</p>
        </div>
      )}
    </div>
  );
}

export default AvisoContent;