import React from 'react';
import LogoSimpleBlack from '../../assets/mobilelogoblack.png';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left-section">
          <p>Cannubis Store</p>
          <p>Todos los derechos reservados</p>
        </div>
        <img id="pc" className="logoblack" src={LogoSimpleBlack} alt="Descripción de la imagen" />
        <div className="footer-right-section">
          <p><a href="politicas-y-terminos">Terminos y Condiciones</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
