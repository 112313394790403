import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBell } from '@fortawesome/free-solid-svg-icons';
import './d-Postnav.css';
import './m-Postnav.css';
import Favoritos from './Favs';
import Cookies from 'js-cookie';  // Importar js-cookie

function Postnav() {
  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let nombreCookie = Cookies.get('nombre');  // Leer el valor de la cookie 'nombre'
      let direccionCookie = Cookies.get('direccion');  // Leer el valor de la cookie 'direccion'

      if (nombreCookie && direccionCookie) {
        setNombre(nombreCookie);
        setDireccion("Enviar a: " + direccionCookie);
      } else {
        try {
          const url = `${window.location.origin}/api/infocuentas`;
          const response = await fetch(url);
          const data = await response.json();

          // Guardar los datos en cookies
          if (data.nombre) {
            Cookies.set('nombre', data.nombre);
            setNombre(data.nombre);
          } else {
            setNombre(<a href="/login" className="custom-link">Ingresá</a>);
          }

          if (data.direccion) {
            Cookies.set('direccion', data.direccion);
            setDireccion("Enviar a: " + data.direccion);
          } else {
            setDireccion(<a href="/login" className="custom-link">Creá tu Cuenta</a>);
          }
        } catch (error) {
          // Manejar errores de la solicitud
          setNombre(<a href="/login" className="custom-link">Ingresá</a>);
          setDireccion(<a href="/login" className="custom-link">Creá tu Cuenta</a>);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="postnav">
      <div className="postnav-full-container">
        <div className="postnav-center-container">
            <div className="postnav-content">
                <div className="postnav-content-byfour">
                    <div className="postnav-enviara">
                        <div>{direccion}</div>
                    </div>
                </div>
                <div className="postnav-content-byfour">
                    <div className="postnav-account">
                        <div>{nombre}</div>
                    </div>
                </div>
                <div className="postnav-content-byfour">
                    <div className="postnav-shopping-favs">
                        <div className="postnav-shopping-favs-content">
                        <a href="/mis-compras" className="custom-link">Mis Compras</a>
                        </div>
                        <div className="postnav-shopping-favs-content">
                        <Favoritos />
                        </div>
                    </div>
                </div>
                <div className="postnav-content-byfour">
                    <div className="postnav-cart-bell">
                        <Link to="/tucarrito" className="custom-link">
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </Link>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                </div>
                <div id="lupa" className="m-postnav-center-lupa">
                    <input className="m-lupa" type="text" id="searchInput" placeholder="Buscar..." />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Postnav;
