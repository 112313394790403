import React from 'react';
import './d-FormContacto.css';

function PrecompraCard({ productos }) {
  return (
    <div id="prebuy-container">
      <div className="center-content-backgroundcolor">
        <div className='center-content-1300'>
          <div id="precompra-card">
            <h2>Confirma tu compra</h2>
            <div id="precompra-item-content">
              {productos.map((producto, index) => (
                <div key={index} className="precompra-item">
                  <p>Producto: {producto.nombre}</p>
                  <p>Precio: {producto.precio}</p>
                  <p>Cantidad: {producto.cantidad}</p>
                  <p>Total: {producto.precio * producto.cantidad}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrecompraCard;
