import React from 'react';
import Postnav from '../../components/Postnav/Postnav';
import Footer from '../../components/Footer/Footer'
import Cart from '../../components/Cart/Cart';


function Cartpage() {

  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <Cart />
            <Footer />
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Cartpage;