import React from 'react';
import Postnav from '../../components/Postnav/Postnav';
import Footer from '../../components/Footer/Footer'
import FormularioContacto from '../../components/FormularioContacto/FormContacto';


function Contacto() {

  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <FormularioContacto />
            <Footer />
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Contacto;