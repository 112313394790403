import React, { useState, useEffect } from 'react';
import './Publicacion.css'; // Asegúrate de tener el CSS para los picklists

const Publicacion = () => {
  const [filtros, setFiltros] = useState([]);
  const [filtroPadreSeleccionado, setFiltroPadreSeleccionado] = useState('');
  const [filtrosHijos, setFiltrosHijos] = useState([]);
  
  // Nuevos estados para los campos adicionales
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [stock, setStock] = useState('');
  const [precio, setPrecio] = useState('');
  const [imagen, setImagen] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/productos/filtros');
        const data = await response.json();
        setFiltros(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Filtra los filtros hijos cuando cambia el filtro padre seleccionado
    const hijos = filtros.filter(filtro => filtro.idfiltropadre === parseInt(filtroPadreSeleccionado));
    setFiltrosHijos(hijos);
  }, [filtroPadreSeleccionado, filtros]);

  const handleFiltroPadreChange = (e) => {
    setFiltroPadreSeleccionado(e.target.value);
  };

  // Manejadores de cambios para los campos adicionales
  const handleTituloChange = (e) => {
    setTitulo(e.target.value);
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleStockChange = (e) => {
    setStock(e.target.value);
  };

  const handlePrecioChange = (e) => {
    setPrecio(e.target.value);
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]); // Obtén el primer archivo seleccionado
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!imagen) {
      console.error('No se ha seleccionado ninguna imagen');
      return;
    }

    const formData = new FormData();
    formData.append('imagen', imagen);

    try {
      const response = await fetch('/api/imgupload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('URL de la imagen:', data.url); // Suponiendo que la respuesta contiene una URL
      } else {
        console.error('Error al subir la imagen');
      }
    } catch (error) {
      console.error('Error en el envío:', error);
    }
  };

  return (
    <div>
      <h2>Componente para Publicar</h2>
      <form onSubmit={handleSubmit}>
        <div className="filtros-picklists">
          <div className="filtro-padre">
            <label htmlFor="padre">Filtro Padre:</label>
            <select id="padre" value={filtroPadreSeleccionado} onChange={handleFiltroPadreChange}>
              <option value="">Selecciona un filtro padre</option>
              {filtros
                .filter(filtro => filtro.idfiltropadre === null)
                .map(filtroPadre => (
                  <option key={filtroPadre.idfiltro} value={filtroPadre.idfiltro}>
                    {filtroPadre.nombre}
                  </option>
                ))}
            </select>
          </div>
          <div className="filtro-hijo">
            <label htmlFor="hijo">Filtro Hijo:</label>
            <select id="hijo" disabled={!filtroPadreSeleccionado}>
              <option value="">Selecciona un filtro hijo</option>
              {filtrosHijos.map(hijo => (
                <option key={hijo.idfiltro} value={hijo.idfiltro}>
                  {hijo.nombre}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="campos-adicionales">
          <div className="campo">
            <label htmlFor="titulo">Título:</label>
            <input 
              id="titulo" 
              type="text" 
              value={titulo} 
              onChange={handleTituloChange} 
              placeholder="Ingrese el título" 
            />
          </div>
          <div className="campo">
            <label htmlFor="descripcion">Descripción:</label>
            <textarea 
              id="descripcion" 
              value={descripcion} 
              onChange={handleDescripcionChange} 
              placeholder="Ingrese la descripción" 
            />
          </div>
          <div className="campo">
            <label htmlFor="stock">Stock:</label>
            <input 
              id="stock" 
              type="number" 
              value={stock} 
              onChange={handleStockChange} 
              placeholder="Ingrese el stock" 
            />
          </div>
          <div className="campo">
            <label htmlFor="precio">Precio:</label>
            <input 
              id="precio" 
              type="number" 
              value={precio} 
              onChange={handlePrecioChange} 
              placeholder="Ingrese el precio" 
            />
          </div>
          <div className="campo">
            <label htmlFor="imagen">Imagen:</label>
            <input 
              id="imagen" 
              type="file" 
              accept="image/*" 
              onChange={handleImagenChange} 
            />
          </div>
        </div>
        <button type="submit">Publicar</button>
      </form>
    </div>
  );
};

export default Publicacion;
