import React, { useEffect, useState } from 'react';
import './d-Cart.css';

function Cart() {
  const [productos, setProductos] = useState([]);
  const [totalCantidad, setTotalCantidad] = useState(0);
  const [totalPrecio, setTotalPrecio] = useState(0);

  useEffect(() => {
    checkAuthentication();
    fetchCarrito();
  }, []);

  const checkAuthentication = async () => {
    try {
      const response = await fetch('/api/infocuentas', {
        method: 'GET',
        credentials: 'include'
      });
      if (response.status === 401) {
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Error al verificar autenticación:', error);
    }
  };

  const fetchCarrito = async () => {
    try {
      const response = await fetch('/api/carrito', {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        if (data.avisos && data.avisos.length > 0) {
          setProductos(data.avisos);
          calcularTotales(data.avisos);
        } else {
          console.error('Tu carrito está vacío, agrega productos para seguir comprando!');
        }
      } else {
        console.error('Error al obtener el carrito:', response.statusText);
      }
    } catch (error) {
      console.error('Error al obtener el carrito:', error);
    }
  };

  const calcularTotales = (productos) => {
    const cantidad = productos.reduce((total, producto) => total + producto.cant, 0);
    const precio = productos.reduce((total, producto) => total + producto.precio * producto.cant, 0);
    setTotalCantidad(cantidad);
    setTotalPrecio(precio);
  };

  const aumentarCantidad = async (index) => {
    const newProductos = [...productos];
    newProductos[index].cant += 1;
    setProductos(newProductos);
    await actualizarCarrito(newProductos[index].avisoid, 1); // Enviar 1 al aumentar la cantidad
    calcularTotales(newProductos); // Actualizar resumen
  };

  const disminuirCantidad = async (index) => {
    const newProductos = [...productos];
    if (newProductos[index].cant > 1) {
      newProductos[index].cant -= 1;
      setProductos(newProductos);
      await actualizarCarrito(newProductos[index].avisoid, -1); // Enviar -1 al disminuir la cantidad
      calcularTotales(newProductos); // Actualizar resumen
    } else {
      console.log("No se puede disminuir más la cantidad");
    }
  };

  const eliminarProducto = async (index) => {
    const productoEliminado = productos[index];
    const newProductos = [...productos];
    newProductos.splice(index, 1);
    setProductos(newProductos);
    await deleteCarrito(productoEliminado.avisoid, 0); // Enviar 0 para indicar que se elimina el producto
    calcularTotales(newProductos); // Actualizar resumen
  };

  const actualizarCarrito = async (avisoid, cantidad) => {
    try {
      const response = await fetch('/api/carrito', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'calc',
          avisos: [{ avisoid, cant: cantidad }] // Enviar el avisoid y la cantidad
        })
      });
      if (!response.ok) {
        console.error('Error al actualizar el carrito:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar el carrito:', error);
    }
  };

  const deleteCarrito = async (avisoid, cantidad) => {
    try {
      const response = await fetch('/api/carrito', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'delete',
          avisos: [{ avisoid, cant: cantidad }] // Enviar el avisoid y la cantidad
        })
      });
      if (!response.ok) {
        console.error('Error al actualizar el carrito:', response.statusText);
      }
    } catch (error) {
      console.error('Error al actualizar el carrito:', error);
    }
  };

  return (
    <div id="prebuy-container">
      <div className="center-content-backgroundcolor">
        <div className='center-content-1300'>
          <div id="precompra-card">
            <h2>Agregaste los siguientes productos al carrito:</h2>
            {productos.length > 0 ? (
              <div id="precompra-item-content">
                {productos.map((producto, index) => (
                  <div key={index} className="precompra-item">
                    <p>Producto: {producto.avisoid}</p>
                    <p>Precio: {producto.precio}</p>
                    <p>Cantidad: {producto.cant}</p>
                    <p>Total: {producto.precio * producto.cant}</p>
                    <button onClick={() => aumentarCantidad(index)}>+</button>
                    <button onClick={() => disminuirCantidad(index)}>-</button>
                    <button onClick={() => eliminarProducto(index)}>Eliminar</button>
                  </div>
                ))}
                <div className="resumen">
                  <p>Total de productos: {totalCantidad}</p>
                  <p>Total a pagar: {totalPrecio}</p>
                </div>
                <button className="continuar-btn">Continuar compra</button>
              </div>
            ) : (
              <p>No hay productos en tu carrito</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
