import React, { useState } from 'react';
import './Toggle.css';

function Toggle({ onToggle }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);

    if (onToggle) {
      onToggle();
    }
  };

  return (
    <div className={`toggle-container ${isActive ? 'activo' : ''}`} onClick={handleClick}>
      <div className="linea"></div>
      <div className="linea"></div>
      <div className="linea"></div>
    </div>
  );
}

export default Toggle;
