import React, { useState, useEffect } from 'react';

const MostrarCookieSession = () => {
  const [cookieSession, setCookieSession] = useState('');

  useEffect(() => {
    // Función para obtener el valor de la cookie por nombre
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return '';
    };

    // Recuperar la cookie 'session'
    const sessionCookie = getCookie('session');
    setCookieSession(sessionCookie);
  }, []);

  return (
    <div>
      <h3>Cookie 'session':</h3>
      <div>{cookieSession ? cookieSession : 'No se encontró la cookie'}</div>
    </div>
  );
};

export default MostrarCookieSession;
