import React, { useState, useEffect, useContext } from 'react';
import Postnav from '../../components/Postnav/Postnav';
import PublicaConNosotros from '../../components/PublicaConNosotros/PublicaConNosotros';
import MisDatos from '../../components/MisDatos/MisDatos';
import InfoCuentas from '../../Hooks/InfoCuentas';
import './d-cuenta.css';
import Loader from '../../components/Loader/Loader';
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';
import CrearTienda from '../../components/PublicaConNosotros/CrearTienda';
import Publicacion from '../../components/PublicaConNosotros/Publicacion';
import Tiendas from '../../components/Tiendas/Tiendas';

function Cuenta() {
  const { isAuthenticated, tiendas } = useContext(InfoCuentasContext); // Asegúrate de que infoCuenta esté en el contexto
  const [componenteActual, setComponenteActual] = useState('PublicaConNosotros');
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = '/';
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); 
      setComponenteActual(hash || 'PublicaConNosotros');
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleClick = (componente) => {
    if (componente === 'CerrarSesion') {
      fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      })
        .then((response) => {
          if (response.ok) {
            // Limpiar localStorage
            localStorage.removeItem('nombre');
            localStorage.removeItem('direccion');
            
            // Redirigir al usuario a la página de inicio
            window.location.href = '/';
          } else {
            throw new Error('Logout failed');
          }
        })
        .catch((error) => {
          console.error('Error during logout:', error);
        });
    } else {
      if (componente === 'Tiendas') {
        if (tiendas === 'no') {
          window.location.hash = 'crear-tienda';
        } else {
          window.location.hash = 'Tiendas';
        }
      } else {
        window.location.hash = componente;
      }
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <div className="maincolumns">
              <div className="cuenta-left-column">
                <div className="cuentas-left-column-totem">
                  <div className="ontop-cuentas-left-column-totem">
                    <button className="boton-cuenta" onClick={() => handleClick('PublicaConNosotros')}>
                      Publica con nosotros!
                    </button>
                  </div>
                  <div className="center-cuentas-left-column-totem">
                    <button className="boton-cuenta" onClick={() => handleClick('MisDatos')}>
                      Mis Datos
                    </button>
                    <button className="boton-cuenta" onClick={() => handleClick('MisCompras')}>
                      Mis Compras
                    </button>
                    <button className="boton-cuenta" onClick={() => handleClick('Tiendas')}>
                      Tiendas
                    </button>
                    <button className="boton-cuenta" onClick={() => handleClick('MisVentas')}>
                      Mis Ventas
                    </button>
                    <button className="boton-cuenta" onClick={() => handleClick('Seguridad')}>
                      Seguridad
                    </button>
                    <div className="apart-cuentas-left-column-totem">
                      <button className="boton-cuenta" onClick={() => handleClick('AdministrarFavoritos')}>
                        Administrar Favoritos
                      </button>
                      <button className="boton-cuenta" onClick={() => handleClick('AlertasBusqueda')}>
                        Alertas de Búsqueda
                      </button>
                    </div>
                  </div>
                  <div className="bottom-cuentas-left-column-totem">
                    <button className="boton-cuenta" onClick={() => handleClick('CerrarSesion')}>
                      Cerrar Sesión
                    </button>
                  </div>
                </div>
              </div>
              <div className="cuenta-center-column">
                {componenteActual === 'PublicaConNosotros' && <PublicaConNosotros />}
                {componenteActual === 'MisDatos' && <MisDatos />}
                {componenteActual === 'Seguridad' && <InfoCuentas />}
                {componenteActual === 'crear-tienda' && <CrearTienda />}
                {componenteActual === 'crear-aviso' && <Publicacion />}
                {componenteActual === 'Tiendas' && <Tiendas />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cuenta;
