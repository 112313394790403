import React from 'react';
import Postnav from '../../components/Postnav/Postnav';
import BigBanners from '../../components/banners/bigbanners/BigBanners';
import ProductosDestacados from '../../components/productos-destacados/ProductosDestacados';
import TiendasDestacadas from '../../components/tiendas-destacadas/TiendasDestacadas';
import MdcOffer from '../../components/banners/longbanners/MdcOffer';
import Footer from '../../components/Footer/Footer'

function Inicio() {
  return (
    <div>
      <div className="outheader">
        <div className="background">
          <div className="background-center-color">
            <div className="postnav-container">
              <Postnav />
            </div>
            <div className='indexcss-margin1'>
              <div className='bigbanner'>
                <div className='indexcss-margin1'>
                  <BigBanners />
                </div>
              </div>
            </div>
            <div className='indexcss-margin1'>
              <div className="center-content-backgroundcolor">
                <div className='center-content-1300'>
                  <ProductosDestacados />
                </div>
              </div>
            </div>
            <div className='indexcss-magin1'>
              <div className='indexcss-magin1'>
                <MdcOffer type="Offer" />
              </div>
            </div>
            <div className='indexcss-margin1'>
              <div className="center-content-backgroundcolor">
                <div className='center-content-1300'>
                  <TiendasDestacadas />
                </div>
              </div>
            </div>
            <div className='indexcss-margin1'>
              <MdcOffer type="Mdc" />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inicio;
