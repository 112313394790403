import React, { useState } from 'react';
import LogoSimpleBlack from './mobilelogoblack.png';
import './Login.css';


function Signin() {
  const [correo, setCorreo] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [confirmarContraseña, setConfirmarContraseña] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validarCorreo = (correo) => {
    const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexCorreo.test(correo);
  };

  const validarContraseña = (contraseña) => {
    const regexContraseña = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    return regexContraseña.test(contraseña);
  };

  const enviarDatos = async (event) => {
    event.preventDefault();

    if (!validarCorreo(correo)) {
      setErrorMessage('Formato de correo electrónico no válido');
      return;
    }

    if (contraseña !== confirmarContraseña) {
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }

    if (!validarContraseña(contraseña)) {
      setErrorMessage('La contraseña debe contener al menos una letra, un número y al menos 8 caracteres');
      return;
    }

    console.log('Enviando datos...');

    const datos = {
      correo: correo,
      contraseña: contraseña
    };

    try {
      const response = await fetch('/api/registro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(datos),
        credentials: 'include'
      });

      console.log('Response:', response);

      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta del servidor:', data);
        window.location.href = '/';
        
      } else {
        if (response.status === 409) { // Cambiar a tu código de error según la respuesta esperada
          setErrorMessage('Correo ya registrado');
        } else {
          console.error('Error en la respuesta del servidor:', response.status);
        }
      }
    } catch (error) {
      console.error('Error en enviarDatos:', error);
    }

    console.log('Datos enviados exitosamente.');
  };

  return (
    
      <div className="centerform">
         <img className="logologin" src={LogoSimpleBlack} alt="Descripción de la imagen" />
          <div className="login-container">
            <h2>Registrate con nosotros</h2>
            <form id="formulario" onSubmit={enviarDatos}>
              <div className="form-group">
                <label htmlFor="correo">Correo electrónico:</label>
                <input type="email" id="correo" name="correo" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="contraseña">Contraseña:</label>
                <input type="password" id="contraseña" name="contraseña" value={contraseña} onChange={(e) => setContraseña(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="confirmarContraseña">Confirmar Contraseña:</label>
                <input type="password" id="confirmarContraseña" name="confirmarContraseña" value={confirmarContraseña} onChange={(e) => setConfirmarContraseña(e.target.value)} required />
              </div>
            </form>
            {errorMessage && <p style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</p>}
            <button id="crear-cuenta-btn" className="rounded-button" onClick={enviarDatos}>Crear cuenta</button>
            <div className="form-group">
                <div className="outbutton">
                    <a href="/login" className="rounded-button">Ya estás registrado? Ingresa</a>
                </div>
            </div>
          </div>
      </div>
    
  );
}

export default Signin;