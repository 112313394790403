import React, { useContext, useState, useEffect } from 'react';
import UpperLoader from '../../components/Loader/UpperLoader';
import { InfoCuentasContext } from '../../Hooks/InfoCuentasContext';

const Tiendas = () => {
  const { tiendas } = useContext(InfoCuentasContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (tiendas) {
      setIsLoading(false);
    }
  }, [tiendas]);

  return (
    <div>
      {isLoading ? (
        <UpperLoader /> // Muestra el loader mientras se está cargando la información
      ) : (
        <p>Esta es tu tienda</p> // Muestra el contenido una vez que la información esté disponible
      )}
    </div>
  );
};

export default Tiendas;
