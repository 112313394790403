import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const InfoCuentasContext = createContext();

export const InfoCuentasProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [tiendas, setTiendas] = useState(''); // Estado para almacenar tiendas
  const location = useLocation(); // Obtener la ubicación actual (URL)

  useEffect(() => {
    // Define las URLs en las que quieres que se ejecute el useEffect
    const allowedUrls = ['/', '/cuenta'];

    if (allowedUrls.includes(location.pathname)) {
      const fetchInfoCuentas = async () => {
        console.log('Fetching info de cuentas...');

        try {
          const response = await fetch('/api/infocuentas');
          console.log('Response Status:', response.status);

          if (response.status === 401) {
            setIsAuthenticated(false);
            console.log('Autenticación fallida');
          } else {
            const data = await response.json(); // Asumiendo que la respuesta es JSON

            // Recuperar datos del localStorage
            setNombre(localStorage.getItem('nombre') || '');
            setDireccion(localStorage.getItem('direccion') || '');
            setTiendas(data.tiendas); // Extrae y almacena el campo tiendas
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchInfoCuentas();
    }
  }, [location.pathname]); // Se ejecutará cuando cambie la URL

  return (
    <InfoCuentasContext.Provider value={{ isAuthenticated, nombre, direccion, tiendas }}>
      {children}
    </InfoCuentasContext.Provider>
  );
};
