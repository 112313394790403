import React, { useState, useEffect } from 'react';
import './PersonalInfo.css';

function PersonalInfo({ onToggle }) {
  const [shouldRender, setShouldRender] = useState(true);
  const [diasEnMes, setDiasEnMes] = useState(31);

  useEffect(() => {
    // Realiza el fetch a /api/infocuentas
    const fetchInfoCuentas = async () => {
      try {
        const response = await fetch('/api/infocuentas');

        if (response.status === 401) {
          // Si la respuesta es un código de estado 401, no renderiza el componente
          setShouldRender(false);
        }
      } catch (error) {
        console.error('Error al realizar el fetch:', error);
      }
    };

    fetchInfoCuentas();
  }, []); // El segundo argumento [] asegura que useEffect se ejecute solo una vez al montar el componente

  const handleMesChange = (event) => {
    const selectedMes = parseInt(event.target.value, 10);
    setDiasEnMes(getDiasEnMes(selectedMes));
  };

  const getDiasEnMes = (mes) => {
    const dias30 = [4, 6, 9, 11];
    return mes === 2 ? 28 : dias30.includes(mes) ? 30 : 31;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Obtener los valores de los campos
    const nombre = document.getElementById('nombre').value;
    const apellido = document.getElementById('apellido').value;
    const dia = document.getElementById('dia').value;
    const mes = document.getElementById('mes').value;
    const ano = document.getElementById('ano').value;
    const dni = document.getElementById('dni').value;
    const telefono = document.getElementById('telefono').value;
    const provincia = document.getElementById('provincia').value;
    const ciudad = document.getElementById('ciudad').value;
    const calle = document.getElementById('calle').value;
    const numero = document.getElementById('numero').value;
    const codigoPostal = document.getElementById('codigoPostal').value;

    // Validar la fecha
    if (!isValidDate(dia, mes, ano)) {
      alert('Fecha de nacimiento no válida. Por favor, ingrese una fecha válida.');
      return;
    }
  
    try {
      // Realizar el fetch a /api/guardar_infocuentas con los datos del formulario
      const response = await fetch('/api/guardar_infocuentas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          nombre: nombre,
          apellido: apellido,
          dni: dni,
          telefono: telefono,
          direccion: `${calle} ${numero} ${codigoPostal}`, // Puedes ajustar el formato de la dirección según tus necesidades
        }),
      });
  
      // Verificar el estado de la respuesta
      if (response.ok) {
        // Realizar acciones después de un éxito, por ejemplo, redirigir a otra página
        console.log('Registro exitoso');
        // Puedes agregar aquí la redirección si es necesario
      } else {
        // Manejar errores, por ejemplo, mostrar un mensaje al usuario
        console.error('Error al realizar el registro');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  const isValidDate = (day, month, year) => {
    const date = new Date(`${year}-${month}-${day}`);
    return !isNaN(date.getTime()) && date.getDate() == day;
  };

  if (!shouldRender) {
    return null;
  }

  return (
    
      <div className="pi-section">
        
          <h2>No olvides completar tus datos personales para coordinar entregas</h2>
          <div className="pi-container">
          <form id="pi-formulario" onSubmit={handleSubmit}>
            <div className="pi-form-group">
              <div className="pi-form-duo">
                  <label htmlFor="nombre">Nombre</label>
                  <input type="text" id="nombre" name="nombre" required />
              </div>
              <div className="pi-form-duo">
                  <label htmlFor="apellido">Apellido</label>
                  <input type="text" id="apellido" name="apellido" required />
              </div>
              <div className="pi-form-duo">
                  <label htmlFor="dni">DNI</label>
                  <input type="text" id="dni" name="dni" required />
              </div>
              <div className="pi-form-fecha">
                <p>Fecha de Nacimiento</p>
                <div className="pi-form-trio">
                  <div className="pi-form-duo">
                    <label htmlFor="mes">Mes</label>
                    <select id="mes" name="mes" onChange={handleMesChange} required>
                      <option value="" disabled selected>Selecciona un mes</option>
                      <option value="1">Enero</option>
                      <option value="2">Febrero</option>
                      <option value="3">Marzo</option>
                      <option value="4">Abril</option>
                      <option value="5">Mayo</option>
                      <option value="6">Junio</option>
                      <option value="7">Julio</option>
                      <option value="8">Agosto</option>
                      <option value="9">Septiembre</option>
                      <option value="10">Octubre</option>
                      <option value="11">Noviembre</option>
                      <option value="12">Diciembre</option>
                    </select>
                  </div>
                  <div className="pi-form-duo">
                    <label htmlFor="dia">Día</label>
                    <input type="number" id="dia" name="dia" min="1" max={diasEnMes} required />
                  </div>
                  <div className="pi-form-duo">
                    <label htmlFor="ano">Año</label>
                    <input type="number" id="ano" name="ano" min="1900" max="2100" required />
                  </div>
                </div>
              </div>
              <div className="pi-form-duo">
                <label htmlFor="telefono">Telefono</label>
                <input type="text" id="telefono" name="telefono" required />
              </div>
              <div className="pi-form-duo">
                <label htmlFor="provincia">Provincia</label>
                <select id="provincia" name="provincia" required>
                    <option value="" disabled selected>Selecciona una provincia</option>
                    <option value="Buenos Aires">Buenos Aires</option>
                    <option value="Catamarca">Catamarca</option>
                    <option value="Chaco">Chaco</option>
                    <option value="Chubut">Chubut</option>
                    <option value="Cordoba">Córdoba</option>
                    <option value="Corrientes">Corrientes</option>
                    <option value="Entre Rios">Entre Ríos</option>
                    <option value="Formosa">Formosa</option>
                    <option value="Jujuy">Jujuy</option>
                    <option value="La Pampa">La Pampa</option>
                    <option value="La Rioja">La Rioja</option>
                    <option value="Mendoza">Mendoza</option>
                    <option value="Misiones">Misiones</option>
                    <option value="Neuquen">Neuquen</option>
                    <option value="Rio Negro">Río Negio</option>
                    <option value="Salta">Salta</option>
                    <option value="San Juan">San Juan</option>
                    <option value="San Luis">San Luis</option>
                    <option value="Santa Cruz">Santa Cruz</option>
                    <option value="Santa Fe">Santa Fe</option>
                    <option value="Santiago del Estero">Santiago del Estero</option>
                    <option value="Tierra del Fuego">Tierra del Fuego</option>
                    <option value="Tucuman">Tucumán</option>
                </select>
              </div>
              <div className="pi-form-duo">
                <label htmlFor="ciudad">Ciudad</label>
                <input type="text" id="ciudad" name="ciudad" required />
              </div>
              <div className="pi-form-duo">
                <label htmlFor="calle">Calle</label>
                <input type="text" id="calle" name="calle" required />
              </div>
              <div className="pi-form-duo">
                <label htmlFor="numero">Numero</label>
                <input type="text" id="numero" name="numero" required />
              </div>
              <div className="pi-form-duo">
                <label htmlFor="codigoPostal">Codigo Postal</label>
                <input type="text" id="codigoPostal" name="codigoPostal" required />
              </div>
            </div>
            <div className="pi-form-duo">
                <button type="submit">Agregar</button>
            </div>
            </form>
          </div>
        </div>
      
    
  );
}

export default PersonalInfo;
