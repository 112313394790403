import React, { useState } from 'react';
import './d-FormContacto.css';

function FormularioContacto() {
  const [formulario, setFormulario] = useState({
    nombre: '',
    correo: '',
    mensaje: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar lógica para enviar el formulario (por ejemplo, a través de una solicitud HTTP)
    console.log('Formulario enviado:', formulario);
  };

  return (
  <div id="form-container">
    <div className="center-content-backgroundcolor">
      <div className='center-content-1300'>
        <div id="form-contacto">
          <h2>Contacto</h2>
          <form className="full-recuadro" onSubmit={handleSubmit}>
          <p>
            Si tenes dudas para publicar, necesitas más información sobre nuestros productos o deseas contactarte con nosotros, no dejes de escribirnos!
          </p>
          <div className="form-up">
            <div className="form-group">
              <label>
                Nombre:
                <input
                  type="text"
                  name="nombre"
                  value={formulario.nombre}
                  onChange={handleChange}
                  />
              </label>
            </div>
            <br />
            <div className="form-group">
            <label>
              Correo electrónico:
              <input
                type="email"
                name="correo"
                value={formulario.correo}
                onChange={handleChange}
                />
            </label>
            </div>
          </div>
          <div className="form-down">
            <br />
            <div className="form-group">
            <label>
              Mensaje:
              <textarea
                name="mensaje"
                value={formulario.mensaje}
                onChange={handleChange}
                />
            </label>
            </div>
            <br />
            <button type="submit">Enviar</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}

export default FormularioContacto;
