import React from 'react';
import './LongBanners.css';

const MdcOffer = ({ type }) => {
  const getImageSource = () => {
    if (type === 'Mdc') {
      return require('../../../assets/mdc.png');
    } else if (type === 'Offer') {
      return require('../../../assets/offer.png');
    } else {
      // Manejar un caso por defecto o mostrar un placeholder si es necesario
      return require('../../../assets/ofer.jpg'); // Reemplaza con la ruta de tu imagen de placeholder
    }
  };

  return (
    <div className="mdc-offer">
      <img src={getImageSource()} alt={type} />
    </div>
  );
};

export default MdcOffer;
