import React, { useState } from 'react';
import './carrousel.css';

const Carrousel = ({ imagenes }) => {
  const [imagenActualIndex, setImagenActualIndex] = useState(0);

  const cambiarImagen = (direccion) => {
    setImagenActualIndex((prevIndex) => {
      let newIndex = prevIndex + direccion;
      
      if (newIndex < 0) {
        newIndex = imagenes.length - 1;
      } else if (newIndex >= imagenes.length) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  return (
    <div className="aviso-container">
      {imagenes.length > 0 && (
        <div className="carrusel">
          <button className="flecha-izquierda" onClick={() => cambiarImagen(-1)}>&lt;</button>
          <div className="slide">
            <img
              src={imagenes[imagenActualIndex]}
              alt={`Imagen${imagenActualIndex + 1}`}
              className="imagen-carrusel"
            />
          </div>
          <button className="flecha-derecha" onClick={() => cambiarImagen(1)}>&gt;</button>
        </div>
      )}
    </div>
  );
};

export default Carrousel;
